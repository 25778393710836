import React, { useRef, useState } from "react";
import { ThreeDots } from "react-loader-spinner";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

const UploadCsv = () => {
  const [file, setFile] = useState();
  const [success, setShowSuccess] = useState(false);
  const [failure, setShowFailure] = useState(false);
  const [uploadButtonDisabled, setUploadButtonDisabled] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successfulTrips, setSuccessfulTrips] = useState([]);
  const [filters, setFilters] = useState({
    day: '',
    tripType: '',
    reason: '',
    search: ''
  });
  const [fileUploaded, setFileUploaded] = useState(false);
  const [showLegend, setShowLegend] = useState(false);
  const [activeTab, setActiveTab] = useState('errors');
  const [serverError, setServerError] = useState(null);

  const handleUpload = () => {
    if (!file) {
      console.error("File is null or undefined.");
      return;
    }

    setUploadButtonDisabled(true);
    setErrors([]);
    setSuccessfulTrips([]);
    setServerError(null);
    
    const formData = new FormData();
    formData.append("bookings_file", file, file.name);
    
    axios.post("/csv_bookings/upload_csv.json", formData)
      .then((res) => {
        const { saved_trips, errors } = res.data;
        setErrors(errors || []);
        setSuccessfulTrips(saved_trips || []);
        setFileUploaded(true);

        if (saved_trips?.length > 0) {
          setShowSuccess(true);
          setTimeout(() => {
            setShowSuccess(false);
          }, 4000);
        }
      })
      .catch((error) => {
        const errorMessage = error.response?.data?.error || 'An unexpected error occurred';
        const errorType = error.response?.data?.error_type || 'server_error';
        
        setServerError({
          message: errorMessage,
          type: errorType
        });
      })
      .finally(() => {
        setUploadButtonDisabled(false);
      });
  };

  const handleChange = (event) => {
    setFile(event.target.files[0]);
    // Clear previous data
    setErrors([]);
    setSuccessfulTrips([]);
    setFileUploaded(false);
    setFilters({
      day: '',
      tripType: '',
      reason: '',
      search: ''
    });
  };

  const filterErrors = (errors) => {
    return errors.filter(error => {
      const matchesDay = filters.day ? new Date(error.date).getDay() === parseInt(filters.day) : true;
      const matchesTripType = filters.tripType ? error.trip_type.toLowerCase() === filters.tripType.toLowerCase() : true;
      const matchesReason = filters.reason ? error.error_type === filters.reason : true;
      const matchesSearch = filters.search 
        ? error.user_name.toLowerCase().includes(filters.search.toLowerCase()) 
        : true;
      
      return matchesDay && matchesTripType && matchesReason && matchesSearch;
    });
  };

  const getStatusColor = (errorType) => {
    const colors = {
      success: 'text-green-600',
      past_date: 'text-orange-600',
      cutoff_passed: 'text-yellow-600',
      inactive: 'text-red-600',
      duplicate: 'text-purple-600',
      validation_error: 'text-brown-600'
    };
    return colors[errorType] || 'text-gray-900';
  };

  const getStatusBgColor = (errorType) => {
    const colors = {
      success: 'bg-green-50',
      past_date: 'bg-orange-50',
      cutoff_passed: 'bg-yellow-50',
      inactive: 'bg-red-50',
      duplicate: 'bg-purple-50',
      validation_error: 'bg-brown-50'
    };
    return colors[errorType] || 'bg-gray-50';
  };

  const getStatus = (booking) => {
    return booking.error_type === 'success' ? booking.status : booking.reason;
  };

  const renderTable = (bookings) => (
    <div className="overflow-x-auto mb-6">
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Employee Name
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Date
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Time
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Trip Type
            </th>
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
              Status
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          {bookings.map((booking, index) => (
            <tr 
              key={index} 
              className={`${getStatusBgColor(booking.error_type)} hover:bg-opacity-75 transition-colors`}
            >
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                {booking.user_name}
              </td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                {
                  booking.date ?
                    new Date(booking.date).toLocaleDateString('en-GB', {
                      weekday: 'long',
                      day: 'numeric',
                      month: 'long',
                      year: 'numeric'
                    }) 
                  : 
                    'N/A' 
                }
              </td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900">
                {booking.time}
              </td>
              <td className="px-6 py-2 whitespace-nowrap text-sm text-gray-900 capitalize">
                {booking.trip_type}
              </td>
              <td className={`px-6 py-2 whitespace-nowrap text-sm font-medium ${getStatusColor(booking.error_type)}`}>
                {getStatus(booking)}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const ErrorAlert = ({ error, onClose }) => (
    <div className="mb-4 bg-red-50 border border-red-200 rounded-md p-4">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <div className="flex-shrink-0">
            <svg className="h-5 w-5 text-red-400" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z" clipRule="evenodd" />
            </svg>
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">
              Upload Error
            </h3>
            <div className="mt-1 text-sm text-red-700">
              {error.message}
            </div>
          </div>
        </div>
        <div>
          <button
            type="button"
            className="inline-flex rounded-md bg-red-50 p-1.5 text-red-500 hover:bg-red-100"
            onClick={onClose}
          >
            <span className="sr-only">Dismiss</span>
            <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
              <path fillRule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clipRule="evenodd" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white pb-4">
      <div className="bookings relative">
        {serverError && (
          <ErrorAlert 
            error={serverError} 
            onClose={() => setServerError(null)}
          />
        )}

        {failure && (
          <h3 className="text-red-600 text-center mb-6">
            Failed to process your request!, please try again.
          </h3>
        )}

        {fileUploaded && (successfulTrips.length > 0 || errors.length > 0) && (
          <>
            {/* Tabs */}
            <div className="border-b border-gray-200 mb-4">
              <nav className="-mb-px flex space-x-8" aria-label="Tabs">
              <button
                  onClick={() => setActiveTab('errors')}
                  className={`${
                    activeTab === 'errors'
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none`}
                >
                  Failed Bookings ({errors.length})
                </button>
                <button
                  onClick={() => setActiveTab('successful')}
                  className={`${
                    activeTab === 'successful'
                      ? 'border-purple-500 text-purple-600'
                      : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300'
                  } whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm focus:outline-none`}
                >
                  Successful Bookings ({successfulTrips.length})
                </button>
              </nav>
            </div>

            {/* Filter Controls and Legend */}
            {activeTab === 'errors' && errors.length > 0 && (
              <>
                <div className="flex justify-between items-center mb-4">
                  <div className="flex flex-wrap gap-4 flex-1">
                    <select 
                      className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:border-gray-400 focus:outline-none focus:border-purple-500"
                      value={filters.day}
                      onChange={(e) => setFilters({...filters, day: e.target.value})}
                    >
                      <option value="">All Days</option>
                      <option value="1">Monday</option>
                      <option value="2">Tuesday</option>
                      <option value="3">Wednesday</option>
                      <option value="4">Thursday</option>
                      <option value="5">Friday</option>
                      <option value="6">Saturday</option>
                      <option value="0">Sunday</option>
                    </select>

                    <select 
                      className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:border-gray-400 focus:outline-none focus:border-purple-500"
                      value={filters.tripType}
                      onChange={(e) => setFilters({...filters, tripType: e.target.value})}
                    >
                      <option value="">All Trip Types</option>
                      <option value="inbound">Inbound</option>
                      <option value="outbound">Outbound</option>
                    </select>

                    <select 
                      className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:border-gray-400 focus:outline-none focus:border-purple-500"
                      value={filters.reason}
                      onChange={(e) => setFilters({...filters, reason: e.target.value})}
                    >
                      <option value="">All Reasons</option>
                      <option value="past_date">Past Date</option>
                      <option value="cutoff_passed">Cut-off Passed</option>
                      <option value="inactive">Inactive User</option>
                      <option value="duplicate">Duplicate Booking</option>
                      <option value="validation_error">Validation Error</option>
                    </select>

                    <input
                      type="text"
                      placeholder="Search by name..."
                      className="px-4 py-2 border border-gray-300 rounded-md text-gray-700 bg-white hover:border-gray-400 focus:outline-none focus:border-purple-500"
                      value={filters.search}
                      onChange={(e) => setFilters({...filters, search: e.target.value})}
                    />
                  </div>
                  
                  <button
                    onClick={() => setShowLegend(!showLegend)}
                    className="ml-4 px-3 py-1 text-sm text-gray-600 border border-gray-300 rounded-md hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-purple-500"
                  >
                    {showLegend ? 'Hide Legend' : 'Show Legend'}
                  </button>
                </div>

                {showLegend && (
                  <div className="mb-4 p-3 bg-gray-50 rounded-md border border-gray-200">
                    <div className="flex flex-wrap gap-6 text-sm">
                      <span className="flex items-center">
                        <span className="w-3 h-3 inline-block bg-orange-50 border border-orange-600 rounded-full mr-2"></span>
                        <span className="text-orange-600">Past Date</span>
                      </span>
                      <span className="flex items-center">
                        <span className="w-3 h-3 inline-block bg-yellow-50 border border-yellow-600 rounded-full mr-2"></span>
                        <span className="text-yellow-600">Cut-off Passed</span>
                      </span>
                      <span className="flex items-center">
                        <span className="w-3 h-3 inline-block bg-red-50 border border-red-600 rounded-full mr-2"></span>
                        <span className="text-red-600">Inactive User</span>
                      </span>
                      <span className="flex items-center">
                        <span className="w-3 h-3 inline-block bg-purple-50 border border-purple-600 rounded-full mr-2"></span>
                        <span className="text-purple-600">Duplicate Booking</span>
                      </span>
                      
                      <span className="flex items-center">
                        <span className="w-3 h-3 inline-block bg-gray-50 border border-gray-600 rounded-full mr-2"></span>
                        <span className="text-gray-600">Invalid Data</span>
                      </span>
                    </div>
                  </div>
                )}
              </>
            )}

            {/* Content based on active tab */}
            {activeTab === 'successful' && successfulTrips.length > 0 && (
              renderTable(successfulTrips.map(trip => ({ ...trip, error_type: 'success' })))
            )}
            {activeTab === 'errors' && errors.length > 0 && (
              renderTable(filterErrors(errors))
            )}
            {activeTab === 'successful' && successfulTrips.length === 0 && (
              <p className="text-center text-gray-500 my-4">No successful bookings</p>
            )}
            {activeTab === 'errors' && errors.length === 0 && (
              <p className="text-center text-gray-500 my-4">No failed bookings</p>
            )}
          </>
        )}

        <div className="mt-6 flex items-center gap-4">
          <div className="flex-1">
            <input
              type="file"
              onChange={handleChange}
              className="w-full p-2 border border-gray-300 rounded-md"
              accept=".csv"
            />
          </div>
          <button
            className={`px-6 py-2 text-white rounded-md ${
              uploadButtonDisabled || !file 
                ? 'bg-gray-400 cursor-not-allowed' 
                : 'bg-purple-600 hover:bg-purple-700'
            }`}
            onClick={handleUpload}
            disabled={uploadButtonDisabled || !file}
          >
            Upload file
          </button>
        </div>

        {uploadButtonDisabled && (
          <div className="absolute inset-0 bg-gray-200 bg-opacity-50 flex items-center justify-center">
            <ThreeDots color="#9B999E" height={100} width={100} />
          </div>
        )}
      </div>
    </div>
  );
};

export default UploadCsv;
