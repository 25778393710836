import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import './tooltip.css';

const DownloadWeeklyTrips = ({ selectedWeek, selectedCompany, selectedTeam }) => {

  const handleDownload = () => {
    if (!selectedWeek || !selectedCompany || !selectedTeam) return;

    const downloadUrl = `/team_manager/download_weekly_trips?week=${selectedWeek.value}&company_id=${selectedCompany.id}&team_id=${selectedTeam.team_id}`;
    window.location.href = downloadUrl;
  };

  return (
    <div className="relative ml-4">
      <button
        onClick={handleDownload}
        disabled={!selectedWeek || !selectedCompany || !selectedTeam}
        className={`px-2 py-2 rounded-md text-sm flex items-center ${
          !selectedWeek || !selectedCompany || !selectedTeam
            ? 'bg-gray-300 cursor-not-allowed'
            : 'bg-purple-500 text-white hover:bg-purple-600 hover:text-white'
        }`}
        style={{ transition: 'all 0.2s ease-in-out' }}
      >
        <FontAwesomeIcon icon={faDownload} />
      </button>
    </div>
  );
};

export default DownloadWeeklyTrips; 