import React, { createRef, useState } from 'react';

import { getDates } from '../../tools/date.util';

import Button from '../button/Button';
import BasicSelect from '../select/BasicSelect';
import Select from 'react-select';
import './trips.scss';

const Trips = ({
  locations,
  timeslots = [],
  employees = [],
  trips = [],
  changeEmployee,
  changeLocation,
  onCopy,
  onEdit,
  onDelete,
  onAdd,
  showAdd,
  onSave,
  onDuplicate,
  onSelect,
  onSelectAll,
  selected,
  editedTrip,
  selectedGeneralEmployees,
  selectedGeneralTrips,
  trained,
}) => {
  const dateRef = createRef();
  const [all, setAll] = useState(false);
  /*const customStyles = {
        option: (provided, state) => ({
          ...provided,
          borderBottom: '1px dotted pink',
          color: state.isSelected ? 'red' : 'blue',
          padding: 20,
        }),
    }*/

  const introBooking = () => {
    const [trip, setTrip] = useState({
      user_id: null,
      checked: false,
      empRef: createRef(),
      timeslot: null,
      slotRef: createRef(),
      location: null,
      pickupRef: createRef(),
      destination: null,
      dropOffRef: createRef(),
      trip_type: 'inbound',
    });

    return (
      <div className="trips__row" key={'trip_intro'}>
        <div className="duplicateTripIntro">
          <div className="trips__select">
            <input
              type="checkbox"
              checked={trip.checked}
              onChange={() =>
                setTrip((prevState) => ({
                  ...prevState,
                  checked: !prevState.checked,
                }))
              }
            />
          </div>
        </div>
        <div className="tripEditIntro flex">
          <div className="trips__value">
            <BasicSelect
              defaultValue={trip.user_id}
              onChange={() => {}}
              placeholder="Select Employee"
              dropdownRef={trip.empRef}
              items={[
                { label: 'Larry Johns', value: 0 },
                { label: 'Thapelo Dlamini', value: 1 },
                { label: 'Tshepo Mulandi', value: 2 },
              ]}
            />
          </div>
          <div className="trips__value">
            <BasicSelect
              defaultValue={trip.timeslot}
              placeholder="Select Timeslot"
              dropdownRef={trip.slotRef}
              items={timeslots}
            />
          </div>
          <div className="trips__value" style={{ textAlign: 'center' }}>
            <BasicSelect
              name="location"
              defaultValue={trip.location}
              placeholder="Select Pick Up"
              dropdownRef={trip.pickupRef}
              items={[
                { label: 'Johannessburg', value: 'Johannessburg' },
                { label: 'Pretoria', value: 'Pretoria' },
              ]}
              onChange={() => {}}
            />
          </div>
          <div className="trips__alue" style={{ textAlign: 'center' }}>
            <BasicSelect
              name="destination"
              defaultValue={trip.destination}
              placeholder="Select Drop Off"
              dropdownRef={trip.dropOffRef}
              items={[
                { label: 'Johannessburg', value: 'Johannessburg' },
                { label: 'Pretoria', value: 'Pretoria' },
              ]}
              onChange={() => {}}
            />
          </div>
        </div>
        <div className="trips__value" style={{ textTransform: 'capitalize' }}>
          {trip.trip_type}
        </div>
        <div
          className="trips__value tripActionsIntro"
          style={{ visibility: 'visible' }}>
          <Button onClick={() => {}}>Edit</Button>
          <Button onClick={() => {}}>Copy</Button>
          <Button onClick={() => {}}>Delete</Button>
        </div>
      </div>
    );
  };

  return (
    <div className="trips">
      <header className="trips__header">
        <div className="trips__select">
          <input
            checked={all}
            onChange={() => {
              setAll(!all);
              onSelectAll(!all);
            }}
            type="checkbox"
          />
        </div>
        <div className="trips__heading">
          <h4>Employee</h4>
        </div>
        <div className="trips__heading">
          <h4>Timeslot</h4>
        </div>
        <div className="trips__heading">
          <h4>Pick Up</h4>
        </div>
        <div className="trips__heading">
          <h4>Drop Off</h4>
        </div>
        <div className="trips__heading">
          <h4>Type</h4>
        </div>
        <div className="trips__heading">
          <h4>Actions</h4>
        </div>
      </header>
      <section className="trips__section">
        {!trained && introBooking()}
        {[...trips, ...selectedGeneralTrips].map((trip) => (
          <div className={`trips__row`} key={trip.key}>
            <div className={`flex ${trip?.status === 'cancelled_early' ? 'line-through text-gray-500' : ''} ${trip?.status === 'cancelled_late' ? 'line-through text-red-500' : ''}`}>
              <div className={`trips__select`}>
                <input
                  type="checkbox"
                  checked={trip.checked}
                  onChange={() => {
                    trip.checked = !trip.checked;
                    onSelect(trip);
                  }}
                />
              </div>
              <div className="trips__value">
                {!trip.new && trip.full_name}
                {trip.new && (
                  <BasicSelect
                    disabled={trip.id && trip.edited ? true : false}
                    defaultValue={trip.user_id}
                    onChange={changeEmployee}
                    placeholder="Select Employee"
                    dropdownRef={trip.empRef}
                    items={employees}
                  />
                )}
              </div>
              <div className="trips__value">
                {!trip.new && trip.timeslot}
                {trip.new && (
                  <BasicSelect
                    defaultValue={
                      timeslots.find((slot) => slot.label == trip.timeslot)?.value
                    }
                    placeholder="Select Timeslot"
                    dropdownRef={trip.slotRef}
                    items={timeslots}
                  />
                )}
              </div>
              <div className="trips__value">
                {!trip.new && (
                  <div
                    style={{
                      textAlign: 'center',
                      textWrap: 'pretty',
                    }}>
                    {trip.location}
                  </div>
                )}
                {trip.new &&
                  locations[trip.empRef?.current?.value ?? trip.user_id] && (
                  <BasicSelect
                    name="location"
                    defaultValue={trip.location}
                    placeholder="Select Pick Up"
                    dropdownRef={trip.pickupRef}
                    items={
                      locations[trip.empRef?.current?.value ?? trip.user_id]
                    }
                    onChange={() => changeLocation(trip)}
                  />
                )}
              </div>
              <div className="trips__value">
                {!trip.new && (
                  <div
                    style={{
                      textAlign: 'center',
                      textWrap: 'pretty',
                    }}>
                    {trip.destination}
                  </div>
                )}
                {trip.new &&
                  locations[trip.empRef?.current?.value ?? trip.user_id] && (
                    <BasicSelect
                      name="destination"
                      defaultValue={trip.destination}
                      placeholder="Select Drop Off"
                      dropdownRef={trip.dropOffRef}
                      items={
                        locations[trip.empRef?.current?.value ?? trip.user_id]
                      }
                      onChange={() => changeLocation(trip)}
                  />
                )}
              </div>
              <div
                className="trips__value"
                style={{ textTransform: 'capitalize' }}>
                {trip.trip_type}
              </div>
            </div>
            <div className="trips__value" style={{ visibility: showAdd }}>
              {!trip.new && trip.id && !selected && (
                <Button 
                  onClick={() => onEdit(trip)}
                  disabled={['cancelled_early', 'cancelled_late'].includes(trip?.status)}
                >
                  Edit
                </Button>
              )}
              <Button 
                onClick={() => onCopy(trip)}
                disabled={['cancelled_early', 'cancelled_late'].includes(trip?.status)}
              >
                Copy
              </Button>
              <Button 
                onClick={() => onDelete(trip)}
                disabled={['cancelled_early', 'cancelled_late'].includes(trip?.status)}
              >
                Delete
              </Button>
            </div>
          </div>
        ))}
        <hr />
        <div className="trips__actions" style={{ visibility: showAdd }}>
          <Button hollow onClick={onAdd}>
            Add
          </Button>
        </div>
        <hr />
        <div id="trips_bottom" />
      </section>
      <footer className="trips__footer">
        {selected && editedTrip && (
          <>
            <Button disabled>
              Please deselect trip and save it before you can duplicate trips
            </Button>
          </>
        )}
        {selected && !editedTrip && (
          <>
            <BasicSelect
              placeholder="Select Date"
              items={getDates()}
              dropdownRef={dateRef}
            />
            <Button
              onClick={() => {
                setAll(false);
                onDuplicate(dateRef.current.value);
              }}>
              Duplicate Bookings
            </Button>
          </>
        )}
        {!selected && (
          <div className="saveTripIntro">
            <Button onClick={onSave}>Save</Button>
          </div>
        )}
      </footer>
      <div id="window_bottom"></div>
    </div>
  );
};

export default Trips;
