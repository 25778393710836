import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState, useEffect, useRef } from "react";

const DateSelection = ({
  dateRanges,
  selectedWeek,
  pageType,
  setWeek,
  setShowWarning,
  setWeekToEdit,
  dailyChangesMade,
  weeklyChangesMade,
}) => {
  const [viewOptions, setViewOptions] = useState(false);
  const dropdownRef = useRef(null);
  const boxRef = useRef(null);

  const toggleView = () => {
    setViewOptions(!viewOptions);
  };

  const handleSelect = (week) => {
    setViewOptions(false);
    if (week.value === selectedWeek.value) return;

    if (pageType === "bookings" && (dailyChangesMade || weeklyChangesMade)) {
      setWeekToEdit(week);
      setShowWarning(true);
    } else {
      setWeek(week);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        boxRef.current &&
        !dropdownRef.current.contains(event.target) &&
        !boxRef.current.contains(event.target)
      ) {
        setViewOptions(false);
      }
    };

    setWeek(sortedDateRanges[0]);

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Function to sort dateRanges array based on the start date
  const sortedDateRanges = [...dateRanges].sort((a, b) => {
    const startDateA = new Date(a.value.split("|")[0]);
    const startDateB = new Date(b.value.split("|")[0]);
    return startDateA - startDateB;
  });

  // Determine the layout of weeks based on pageType
  let weekLayout = [
    sortedDateRanges[2], // Last week
    sortedDateRanges[0], // This week  
    sortedDateRanges[1], // Next week
    sortedDateRanges[3], // Next week 2
  ];

  // Exclude the future weeks if pageType is "downloads"
  if (pageType === "downloads") {
    weekLayout = [sortedDateRanges[0], sortedDateRanges[1]]; // Keep only current and next week
  }

  const weekLayoutMapped = weekLayout.map((range, index) => {
    let label = range.label;
    let suffix = '';
    
    if (pageType !== "downloads") {
      if (index === 0) {
        suffix = ' [Last Week]';
      } else if (index === 1) {
        suffix = ' [This Week]';
      } else if (index === 2) {
        suffix = ' [Next Week]';
      } else if (index === 3) {
        suffix = ' [Following Week]';
      }
    }
    
    return {
      ...range,
      displayLabel: `${label}${suffix}`
    };
  });

  return (
    <>
      <div
        className="weekSelect flex justify-between items-center bg-gray-200 rounded-lg w-full text-sm px-2 py-1 relative"
        onClick={toggleView}
        ref={boxRef}
      >
        <div className="text-gray-600 font-small">{selectedWeek.label}</div>
        <FontAwesomeIcon icon={faCalendarAlt} className="text-gray-600" />
        {viewOptions && (
          <div
            ref={dropdownRef}
            className="absolute top-full left-0 bg-white rounded-lg shadow-md border border-gray-300 w-full overflow-hidden z-40"
            style={{ top: "calc(100%)" }}
          >
            {weekLayoutMapped.map((range, index) => (
              <div
                key={index}
                className={`px-4 py-1 text-gray-600 font-normal cursor-pointer hover:bg-gray-100`}
                onClick={() => handleSelect(range)}
              >
                {range.displayLabel}
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
};

export default DateSelection;
