import moment from 'moment';

export const getCurrentWeek = () => {
    const currentDate = moment();
    const weekStart = currentDate.clone().startOf('isoWeek');
    const days = [];

    for (let i = 0; i < 7; i++) {
        const value = moment(weekStart).add(i, 'days').format('DD-MM-YYYY');
        days.push({ value, label: value });
    }

    return days;
};

export const getNextWeek = () => {
    const currentDate = moment().add(1, 'week');
    const weekStart = currentDate.clone().startOf('isoWeek');
    const days = [];

    for (let i = 0; i < 7; i++) {
        const value = moment(weekStart).add(i, 'days').format('DD-MM-YYYY');
        days.push({ value, label: value });
    }

    return days;
};

export const getDates = () => {
    const nextDay = moment().add(1, 'days');
    const endOfNextWeek = moment().add(1, 'week').clone().endOf('isoWeek');
    const days = [];

    for (let i = 0; i <= endOfNextWeek.diff(nextDay, 'days'); i++) {
        const value = moment(nextDay).add(i, 'days').format('DD-MM-YYYY');
        days.push({ value, label: moment(value, 'DD-MM-YYYY').format('D MMM YYYY (ddd)') });
    }

    return days;
};

export const getThisWeekDateRange = () => {
    return moment().clone().startOf('isoWeek').format('D')+ '-' + moment().endOf('isoWeek').format('D MMM YYYY');
}

export const getNextWeekDateRange = () => {
    return moment().add(1, 'week').clone().startOf('isoWeek').format('D') + '-' + moment().add(1, 'week').clone().endOf('isoWeek').format('D MMM YYYY');
}

export const getLastWeekDateRange = () => {
    return moment().subtract(1, 'week').clone().startOf('isoWeek').format('D') + '-' + moment().subtract(1, 'week').clone().endOf('isoWeek').format('D MMM YYYY');
}

export const getLastWeekDateRangeOption = () => {
    const startDate = moment().subtract(1, 'weeks').startOf('isoWeek');
    const endDate = moment().subtract(1, 'weeks').endOf('isoWeek');
    return {
        label: `${startDate.format('DD MMM')} - ${endDate.format('DD MMM YYYY')}`,
        value: `${startDate.format('DD-MM-YYYY')}|${endDate.format('DD-MM-YYYY')}`
    };
}

export const getThisWeekDateRangeOption = () => {
    const startDate = moment().startOf('isoWeek');
    const endDate = moment().endOf('isoWeek');
    return {
        label: `${startDate.format('DD MMM')} - ${endDate.format('DD MMM YYYY')}`,
        value: `${startDate.format('DD-MM-YYYY')}|${endDate.format('DD-MM-YYYY')}`
    };
}

export const getNextWeekDateRangeOption = () => {
    const startDate = moment().add(1, 'weeks').startOf('isoWeek');
    const endDate = moment().add(1, 'weeks').endOf('isoWeek');
    return {
        label: `${startDate.format('DD MMM')} - ${endDate.format('DD MMM YYYY')}`,
        value: `${startDate.format('DD-MM-YYYY')}|${endDate.format('DD-MM-YYYY')}`
    };
}

export const getNextWeek2DateRangeOption = () => {
    const startDate = moment().add(2, 'weeks').startOf('isoWeek');
    const endDate = moment().add(2, 'weeks').endOf('isoWeek');
    return {
        label: `${startDate.format('DD MMM')} - ${endDate.format('DD MMM YYYY')}`,
        value: `${startDate.format('DD-MM-YYYY')}|${endDate.format('DD-MM-YYYY')}`
    };
};

export const getFormattedDate = (date) =>{
    var momentObj = moment(date);
    var momentString = momentObj.format('YYYY/MM/DD'); // 2016-07-15
    return momentString;
}

export const getFormattedDateDisplay = (date) =>{
    var momentObj = moment(date);
    var momentString = momentObj.format('DD/MM/YYYY'); // 15-07-2016
    return momentString;
}

export const getFormattedTimeSlot = (timeslot) => {
    let h = parseInt(timeslot.slice(0,2));
    const m = timeslot.slice(3,5);
    const p = timeslot.slice(5);
    let twelveHourFormat = '';
    if (h == 12 && p == 'AM'){
        h = '00'
        twelveHourFormat = `${h}:${m}${p}`
    }else if (h > 12 && p == 'PM') {
      h -= 12
      const formattedHour = h < 10 ? `0${h}` : h;
      twelveHourFormat = `${formattedHour}:${m}${p}`
    }else{
      twelveHourFormat = timeslot
    }
    return twelveHourFormat;

  }

export const getFormattedTimeSlot24 = (timeslot) => {
    let h = parseInt(timeslot.slice(0,2));
    const m = timeslot.slice(3,5);
    const p = timeslot.slice(5);
    let twentyFourHourFormat = '';

    if (h < 12 && p.trim() == 'PM') {
        h += 12
        const formattedHour = h < 10 ? `0${h}` : h;
        twentyFourHourFormat = `${formattedHour}:${m.trim()}${p.trim()}`
    }else{
        twentyFourHourFormat = timeslot
    }
    return twentyFourHourFormat;
}
