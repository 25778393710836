import React from "react";

function UnsavedChanges({ handleNoClick, handleYesClick }) {
  return (
    <div className="fixed inset-0 flex items-center justify-center z-50">
      <div className="modal-bg absolute inset-0 bg-gray-800 opacity-75"></div>
      <div className="modal relative bg-white p-8 rounded-lg shadow-lg" style={{ minWidth: "320px" }}>
        <div className="text-center mb-6">
          <p className="text-gray-700 whitespace-normal">
            You have unsaved changes. Do you want to continue?
          </p>
        </div>
        <div className="flex justify-center gap-4">
          <button
            onClick={handleNoClick}
            className="min-w-[100px] bg-gray-500 hover:bg-gray-600 text-white font-medium py-2 px-6 rounded focus:outline-none transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={handleYesClick}
            className="min-w-[100px] bg-purple-500 hover:bg-purple-600 text-white font-medium py-2 px-6 rounded focus:outline-none transition-colors"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
}

export default UnsavedChanges;
