import React, { useState, useEffect } from "react";
import axios from 'axios';

const DownloadCsv = ({
  selectedTimeslot,
  outboundTimeslot,
  inboundTimeslot,
  selectedEmployees,
  selectedWeek,
  selectedCompanyAddress,
  tripType,
  selectedCompany,
}) => {
  const [timeslotsMap, setTimeslotsMap] = useState({});

  useEffect(() => {
    fetchTimeslots();
  }, []);

  const fetchTimeslots = async () => {
    try {
      const response = await axios('/csv_bookings/time_slots', {
        headers: { Accept: 'application/json' },
      });
      setTimeslotsMap(response.data);
    } catch (error) {
      console.error("Error fetching timeslots:", error);
    }
  };

  const extractIds = (employees) => {
    if (!employees) return [];
    return employees.map((employee) => employee.id);
  };

  const employees = extractIds(selectedEmployees);

  // Get the index values for the selected times
  const getTimeSlotIndex = (time) => timeslotsMap[time] || time;

  const inboundIndex = getTimeSlotIndex(inboundTimeslot);
  const outboundIndex = getTimeSlotIndex(outboundTimeslot);

  const isDisabled = 
    !inboundTimeslot || 
    !outboundTimeslot || 
    !selectedEmployees?.length || 
    !selectedCompanyAddress ||
    selectedCompanyAddress.name === "Select an Address" || 
    !selectedWeek;

  return (
    <div className="my-2">
      {isDisabled ? (
        <button className="bookingsDownload bg-gray-300 text-sm text-gray-500 cursor-not-allowed mt-2 py-2 px-4 rounded-full">
          Download CSV
        </button>
      ) : (
        <div className="mt-4">
          <a
            href={`/csv_bookings/download_csv.csv?company_id=${
              selectedCompany.id
            }&employees=${
              employees.join("|")
            }&timeslot_inbound=${
              inboundIndex
            }&timeslot_outbound=${
              outboundIndex
            }&company_address=${
              selectedCompanyAddress.name
            }&week=${
              selectedWeek.value
            }`}
            className="text-sm text-white py-2 px-4 rounded-full bg-purple-500 hover:bg-purple-700"
          >
            Download CSV
          </a>
        </div>
      )}
    </div>
  );
};

export default DownloadCsv;
