import React, { useEffect, useState, useCallback, useRef } from 'react';
import Select from 'react-select';

const DynamicSelect = ({ props }) => {
  const [selectedOption, setSelectedOption] = useState(null);
  const [menuPlacement, setMenuPlacement] = useState('auto');
  const selectRef = useRef(null);
  const selectId = `${props.hiddenInputId}_select`;
  const options = props.resetOption
    ? [{ label: props.resetOption, value: null }, ...props.options]
    : props.options;

  const calculateMenuPlacement = useCallback(() => {
    if (!selectRef.current) return;
    
    const rect = selectRef.current.getBoundingClientRect();
    const bottomSpace = window.innerHeight - rect.bottom;
    const menuHeight = Math.min(300, 45 * options.length); // Max height of 300px
    
    setMenuPlacement(bottomSpace < menuHeight + 20 ? 'top' : 'bottom');
  }, [options.length]);

  useEffect(() => {
    if (props.value) {
      const valuesArray = props.isMulti
      ? props.value.split(',').map(val => val.toString())
      : [props.value.toString()];

      const selectedValues = options.filter(option =>
        valuesArray.includes(option.value?.toString())
      );

      handleChange(props.isMulti ? selectedValues : selectedValues[0]);
    } else if (props.resetOption) {
      handleChange(options[0]);
    }
  }, []);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);

    if (props.hiddenInputId) {
      const hiddenInput = document.getElementById(props.hiddenInputId);
      if (hiddenInput) {
        if (props.isMulti) {
          if (selectedOption.length > 0) {
            hiddenInput.value = selectedOption
              ? selectedOption.filter(option => !!option.value).map((option) => option.value).join(',')
              : '';
          } else {
            hiddenInput.value = '';
          }
        } else {
          hiddenInput.value = selectedOption ? selectedOption.value : '';
        }
      }
    }
    
    // This is for the vehicles view to update the vehicle type field
    if (props.onCarSelected) {
      onCarSelected(selectedOption?.value || null);
    }

    // This is for the Book trip page to inject the Location and Destination to the other dropdowns
    if (props.setLocationDestination) {
      setLocationDestination(selectedOption?.value || null);
    }
  };

  const customStyles = {
    control: (baseStyles, state) => ({
      ...baseStyles,
      borderColor: state.isFocused ? '#6b7280' : '#d1d5db',
      boxShadow: state.isFocused ? '0 0 0 1px #6b7280' : 'none',
      backgroundColor: props.backgroundColor || 'white',
      border: '1px solid #d1d5db',
      color: state.isFocused ? '#FFFFFF' : '#716C6E',
      borderRadius: '0.375rem',
      fontSize: '14px',
      lineHeight: '1.25rem',
      transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
      boxSizing: 'border-box',
      width: 'full',
      padding: '0.275rem 0.25rem 0.275rem 0.5rem',
      display: 'flex',
      cursor: 'pointer',
    }),
    menu: (baseStyles) => ({
      ...baseStyles,
      zIndex: 1000,
      position: 'absolute',
      width: '100%',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)',
    }),
    menuList: (baseStyles) => ({
      ...baseStyles,
      maxHeight: '300px',
      overflowY: 'auto',
      '&::-webkit-scrollbar': {
        width: '8px'
      },
      '&::-webkit-scrollbar-track': {
        background: '#f1f1f1'
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '4px'
      },
      '&::-webkit-scrollbar-thumb:hover': {
        background: '#555'
      }
    }),
    option: (baseStyles, state) => ({
      ...baseStyles,
      color: state.isSelected ? '#FFFFFF' : '#716C6E',
    }),
    singleValue: (baseStyles) => ({
      ...baseStyles,
      color: '#716C6E',
      '&:hover': {
        color: 'white',
        backgroundColor: '#4299E1',
      },
    }),
    indicatorSeparator: (baseStyles) => ({
      ...baseStyles,
      backgroundColor: props.backgroundColor || '#FFFFFF',
    }),
  };

  return (
    <div ref={selectRef}>
      <Select
        id={selectId}
        key={selectId}
        options={options}
        isMulti={props.isMulti}
        onChange={handleChange}
        value={selectedOption}
        placeholder={props.placeholder}
        menuPlacement={menuPlacement}
        onMenuOpen={calculateMenuPlacement}
        styles={customStyles}
      />
    </div>
  );
};

export default DynamicSelect;
