// removes the hoost element from the dom after 1 second
// Perfect for flash notifications that need to disapear after a short period of time
// Usage:
// <div data-controller="self-destruct">
//   <!-- Notification content here -->
// </div>

import { Controller } from "stimulus"

export default class extends Controller {
  connect() {
    const notification = this.element

    setTimeout(() => notification.remove(), 1000)
  }
}
